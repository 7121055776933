export const APP_ENVIRONMENT = process.env[
  'NEXT_PUBLIC_APP_ENVIRONMENT'
] as string;

export const GOOGLE_TRACKING_ID = process.env[
  'NEXT_PUBLIC_GOOGLE_TRACKING_ID'
] as string;

export const LAMBDA_BASE_URL = process.env[
  'NEXT_PUBLIC_LAMBDA_BASE_URL'
] as string;

export const HASURA_GRAPHQL_ENDPOINT = process.env[
  'NEXT_PUBLIC_HASURA_GRAPHQL_ENDPOINT'
] as string;

export const GATEWAY_GRAPHQL_ENDPOINT = process.env[
  'NEXT_PUBLIC_GATEWAY_GRAPHQL_ENDPOINT'
] as string;

export const MAGIC_PUBLISHABLE_KEY = process.env[
  'NEXT_PUBLIC_PUBLISHABLE_API_KEY'
] as string;

export const CLOUD_FRONT_BASE = process.env[
  'NEXT_PUBLIC_CLOUD_FRONT_BASE'
] as string;

export const ALCHEMY_API_KEY = process.env[
  'NEXT_PUBLIC_ALCHEMY_API_KEY'
] as string;

export const APP_URL = process.env['NEXT_PUBLIC_APP_URL'] as string;

export const OPENSEA_API_KEY = process.env[
  'NEXT_PUBLIC_OPENSEA_API_KEY'
] as string;
