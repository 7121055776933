import axios from 'axios';
import constate from 'constate';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Enum_Store_Theme_Enum } from '../graphql/users.generated';

type PriceResponse = {
  ethereum: {
    usd: number;
  };
};
export interface StoreContextType {
  usdPrice: PriceResponse | undefined;
  loading: boolean;
  currentTheme: Enum_Store_Theme_Enum;
  setCurrentTheme: Dispatch<SetStateAction<Enum_Store_Theme_Enum>>;
}

function useStoreContext(): StoreContextType {
  const [loading, setLoading] = useState<boolean>(true);
  const [currentTheme, setCurrentTheme] = useState<Enum_Store_Theme_Enum>(
    Enum_Store_Theme_Enum.BlackWhitez
  );

  const coinGeckoURL =
    'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd';
  const [usdPrice, setUSDPrice] = useState<PriceResponse | undefined>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await axios.get(coinGeckoURL);
        setUSDPrice(res.data);
      } catch (e) {
        console.error(e);
      }

      setLoading(false);
    })();
    return () => {
      setLoading(false);
    };
  }, []);

  return {
    usdPrice,
    loading,
    currentTheme,
    setCurrentTheme,
  };
}
export const [StoreProvider, useStore] = constate(useStoreContext);
