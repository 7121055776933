import { Enum_Providers_Enum } from '@hoji/shared-backend';
import { useCookieState } from 'ahooks';
import constate from 'constate';
import { deleteCookie, setCookie } from 'cookies-next';
import JWTDecode, { JwtPayload } from 'jwt-decode';
import moment from 'moment';
import { magic } from '../helpers/magic-client.helper';

interface AuthSessionData {
  jwt_token: string;
  account_id: string;
  provider: Enum_Providers_Enum;
}

function useAuthContext() {
  const [isLoggedIn, setIsLoggedIn] = useCookieState('is-authenticated', {
    defaultValue: 'unauthenticated',
  });
  const [accountId, setAccountId] = useCookieState('set-account-id', {
    defaultValue: '',
  });
  const [username, setUsername] = useCookieState('set-username', {
    defaultValue: '',
  });

  const [hasuraJWT, setHasuraJWT] = useCookieState('hasura-jwt', {
    defaultValue: '',
  });

  const handleLogin = ({ account_id, jwt_token }: AuthSessionData) => {
    const decodedJWT = JWTDecode<JwtPayload>(jwt_token);
    const expires = moment
      .unix(decodedJWT.exp as number)
      .subtract(20, 'second')
      .toDate();
    setHasuraJWT(jwt_token, { expires });
    setIsLoggedIn('authenticated', { expires });
    setAccountId(account_id, { expires });
  };

  const handleLogout = async () => {
    await magic?.user?.logout();
    setIsLoggedIn('unauthenticated');
    setAccountId('');
    setUsername('');
    deleteCookie('hasura-jwt');
    setCookie('is-authenticated', 'unauthenticated');
  };

  return {
    isLoggedIn: isLoggedIn === 'authenticated',
    setIsLoggedIn,
    accountId,
    setAccountId,
    handleLogin,
    handleLogout,
    username,
    setUsername,
  };
}

export const [AuthProvider, useAuth] = constate(useAuthContext);
