import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import ReactGA from 'react-ga4';

import {
  GATEWAY_GRAPHQL_ENDPOINT,
  GOOGLE_TRACKING_ID,
  HASURA_GRAPHQL_ENDPOINT,
} from '../../config';
import { LayoutNames } from '../constants/layout.constant';
import { AuthProvider } from '../contexts/auth.context';
import { GraphQLProvider } from '../contexts/graphql.context';
import { SDKProvider } from '../contexts/sdk.context';
import { LayoutProvider } from '../layouts/layout-provider';
import './styles.less';

type CustomAppProps = AppProps & {
  Component: NextPage & {
    layout: LayoutNames;
    title?: string;
  };
};
ReactGA.initialize(GOOGLE_TRACKING_ID);
if (typeof window !== 'undefined') {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  });
}
function CustomApp({ Component, pageProps }: CustomAppProps) {
  const { layout } = Component;
  return (
    <>
      <Head>
        <link
          rel="icon"
          type="image/png"
          sizes="64x64"
          href="/assets/hoji_favicon_64x64.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/hoji_logo_light.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/hoji_logo_light.png"
        />
      </Head>
      <main>
        <AuthProvider>
          <GraphQLProvider
            url={`${HASURA_GRAPHQL_ENDPOINT}/v1/graphql`}
            gatewayURL={GATEWAY_GRAPHQL_ENDPOINT}
          >
            <SDKProvider url={`${HASURA_GRAPHQL_ENDPOINT}/v1/graphql`}>
              <LayoutProvider layout={layout}>
                <Component {...pageProps} />
              </LayoutProvider>
            </SDKProvider>
          </GraphQLProvider>
        </AuthProvider>
      </main>
    </>
  );
}

export default CustomApp;
