import React from 'react';
import { LayoutNames } from '../constants/layout.constant';
import { NormalLayout } from './normal-layout';
import { StoreLayout } from './store-layout';

interface LayoutProviderProps {
  children: React.ReactNode;
  layout: LayoutNames;
}

export function LayoutProvider({ layout, children }: LayoutProviderProps) {
  return layout === LayoutNames.Store ? (
    <StoreLayout>{children}</StoreLayout>
  ) : (
    <NormalLayout>{children}</NormalLayout>
  );
}
