import { getCookie } from 'cookies-next';
import { GraphQLClient } from 'graphql-request';
import { HASURA_GRAPHQL_ENDPOINT } from '../../config';
import { getSdk } from '../generated/main-sdk';

export const createHasura = (gqlEndPoint: string) => {
  const client = new GraphQLClient(gqlEndPoint, {
    headers: () => {
      const authToken = getCookie('hasura-jwt');
      return authToken
        ? {
            Authorization: `Bearer ${authToken}`,
          }
        : undefined;
    },
  });

  return getSdk(client);
};

export const sdk = createHasura(`${HASURA_GRAPHQL_ENDPOINT}/v1/graphql`);
