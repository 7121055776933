import { Layout } from 'antd';
import dynamic from 'next/dynamic';

const MainHeader = dynamic(() => import('../components/headers/main-header'), {
  ssr: false,
});
export function NormalLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <MainHeader />
      <Layout.Content className="w-full h-full">{children}</Layout.Content>
    </>
  );
}
