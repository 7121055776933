import constate from 'constate';
import { useState } from 'react';
import { createHasura } from '../helpers/hasura.helper';

interface SdkContextProps {
  url: string;
}
const useSdkContext = ({ url }: SdkContextProps) => {
  const [sdk, setSDK] = useState(createHasura(url));

  return sdk;
};

export const [SDKProvider, useClientSDK] = constate(useSdkContext);
