import { Provider as FoundationProvider } from '@f8n/foundationkit-hooks';
import dynamic from 'next/dynamic';
import { StoreProvider } from '../contexts/store.context';
const StoreHeader = dynamic(
  () => import('../components/headers/store-header'),
  {
    ssr: false,
  }
);

export function StoreLayout({ children }: { children: React.ReactNode }) {
  return (
    <StoreProvider>
      <StoreHeader />
      <FoundationProvider>
        <div className="min-h-screen min-w-screen">{children}</div>
      </FoundationProvider>
    </StoreProvider>
  );
}
