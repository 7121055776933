import { OAuthExtension } from '@magic-ext/oauth';
import { Magic } from 'magic-sdk';
import { MAGIC_PUBLISHABLE_KEY } from '../../config';

const createMagic = () => {
  return typeof window !== 'undefined'
    ? new Magic(MAGIC_PUBLISHABLE_KEY, {
        extensions: [new OAuthExtension()],
      })
    : undefined;
};

export const magic = createMagic();
