import {
  ApolloClient,
  ApolloProvider,
  concat,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { getCookie } from 'cookies-next';
import { createClient } from 'graphql-ws';
import React, { useState } from 'react';
import { GraphQLAPIServerNames } from '../constants/graphql.constant';

const createGQLClient = (url: string, gatewayURL: string) => {
  const httpLink = createHttpLink({
    uri: url,
  });
  const httpObjktLink = createHttpLink({
    uri: gatewayURL,
  });

  const wsLink =
    typeof window !== 'undefined'
      ? new GraphQLWsLink(
          createClient({
            connectionParams: () => {
              const authToken = getCookie('hasura-jwt');

              return {
                headers: authToken
                  ? {
                      Authorization: `Bearer ${authToken}`,
                    }
                  : undefined,
              };
            },
            url: url.replace('http://', 'ws://').replace('https://', 'wss://'),
          })
        )
      : null;
  const selectHasuraVsObjkt = split(
    (operation) =>
      operation.getContext()['serverName'] === GraphQLAPIServerNames.gateway,
    httpObjktLink, //if above
    httpLink
  );

  const splitLink =
    typeof window !== 'undefined'
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            );
          },
          wsLink as GraphQLWsLink,
          selectHasuraVsObjkt
        )
      : httpLink;

  const authLink = setContext((_, { headers }) => {
    const authToken = getCookie('hasura-jwt');

    const headersNew = {
      ...headers,
    };

    if (authToken) {
      headersNew['Authorization'] = `Bearer ${authToken}`;
    }

    return {
      headers: headersNew,
    };
  });
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: concat(authLink, splitLink),
    cache: new InMemoryCache(),
  });
};

export interface GraphQLProviderProps {
  children: React.ReactNode;
  gatewayURL: string;
  url: string;
}

export const GraphQLProvider = ({
  children,
  gatewayURL,
  url,
}: GraphQLProviderProps) => {
  const [client, setSDK] = useState(createGQLClient(url, gatewayURL));

  if (!client) return null;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
